import React from 'react';
import { useDrop } from 'react-dnd';
import CategoryBox from './CategoryBox';

interface RankingBoxProps {
    rank: number;
    categories: string[];
    moveCategory: (category: string, rank: number) => void;
}

const RankingBox: React.FC<RankingBoxProps> = ({ rank, categories, moveCategory }) => {
    const [, drop] = useDrop(() => ({
        accept: 'CATEGORY',
        drop: (item: { category: string }) => moveCategory(item.category, rank),
    }));

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '30px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>{rank}</div>
            <div
                ref={drop}
                style={{
                    width: '600px',
                    height: '50px',
                    margin: '4px',
                    backgroundColor: 'lightgrey',
                    border: '1px solid black',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {
                    categories.map((category, idx) => (
                        <CategoryBox key={category} category={category} />
                    ))
                }
            </div>
        </div>
    );
};

export default RankingBox;