import React from 'react';

interface PrevButtonProps {
  handlePrevButton: () => void;
}

const PrevButton: React.FC<PrevButtonProps> = ({ handlePrevButton }) => {
  return (
    <button
      style={{
        position: 'fixed',
        left: '15px',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '10px 20px',
        backgroundColor: 'lightblue',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      onClick={handlePrevButton}
    >
      <span style={{ marginLeft: '5px' }}>←</span> Prev
    </button>
  );
};

export default PrevButton;