import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import ChartComponent from './ChartComponent/ChartComponent';
import NextButton from './Components/NextButton';
import NextPageComponent from './Components/NextPageComponent';
import DistributionComponent from './DistributionComponent/DistributionComponent';
import RankingComponent from './RankingComponent/RankingComponent';
import SatisfactionComponent from './SatisfactionComponent/SatisfactionComponent';
import './YoutubeEmbedComponent/YoutubeEmbedComponent.css';
import YoutubeEmbed from './YoutubeEmbedComponent/YoutubeEmbedComponet';

const categories = [
  "Significant Other",
  "Family",
  "Friendship",
  "Physical Health / Sports",
  "Mental Health / Mindfulness",
  "Spirituality / Faith",
  "Community / Citizenship",
  "Societal Engagement",
  "Job / Career",
  "Education / Learning",
  "Finances",
  "Hobbies / Interests",
  "Online Entertainment",
  "Offline Entertainment",
  "Physiological Needs",
  "Activities Of Daily Living"
]

const data = [
  { satisfaction: 2, importance: 3, hours: 5, category: 'Work' },
  { satisfaction: 4, importance: 7, hours: 10, category: 'Health' },
  { satisfaction: 6, importance: 5, hours: 15, category: 'Family' },
  { satisfaction: 8, importance: 2, hours: 20, category: 'Hobbies' },
  { satisfaction: 3, importance: 8, hours: 25, category: 'Education' },
];

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/next" element={<NextPageComponent />} />
            <Route path="/time-distribution" element={<DistributionComponent categories={categories} totalHours={168} />} />
            <Route path="/ranking" element={<RankingComponent categories={categories} />} />
            <Route path="/satisfaction" element={<SatisfactionComponent categories={categories} />} />
            <Route path="/chart" element={<ChartComponent data={data} categories={categories} />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

function Home() {
  const navigate = useNavigate();

  return (
    <div>
      <header>
        <h1>PERMAV Life Portfolio</h1>

        <div>Generates a 2x2 Strategic Life Portfolio based on the 16 Strategic Life Units (SLUs)</div>
        <div>Read the full article: <a href="https://hbr.org/2023/12/use-strategic-thinking-to-create-the-life-you-want">https://hbr.org/2023/12/use-strategic-thinking-to-create-the-life-you-want</a></div>
      </header>
      <p>Example result:</p>
      <img width={'500px'} src={require('./public/example_chart.png')} alt="Example Chart" />
      <p>Video format of article from Harvard Business Review:</p>
      <YoutubeEmbed embedId="dbiNhAZlXZk" />
      <p>Hit Next on the right hand side to get started!</p>
      <NextButton handleNextButton={() => navigate('/time-distribution')} />
    </div>
  );
}

export default App;