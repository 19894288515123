import React from 'react';

interface NextButtonProps {
  handleNextButton: () => void;
}

const NextButton: React.FC<NextButtonProps> = ({ handleNextButton }) => {
  return (
    <button
      style={{
        position: 'fixed',
        right: '15px',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '10px 20px',
        backgroundColor: 'lightblue',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      onClick={handleNextButton}
    >
      Next <span style={{ marginLeft: '5px' }}>→</span>
    </button>
  );
};

export default NextButton;