import React from 'react';
import { useNavigate } from 'react-router';
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryScatter, VictoryTheme } from 'victory';
import PrevButton from '../Components/PrevButton';

interface DataPoint {
  satisfaction: number;
  importance: number;
  hours: number;
  category: string;
}

interface ChartComponentProps {
  data: DataPoint[];
  categories: string[];
}

// TODO: if two circles are on top of each other, change the position of the label for one or the other
// TODO: if two circles are on top of each other, show the smaller one on top
const buildChartDataFromLocalStorage = (categories: string[]): DataPoint[] => {
  // Build data from local storage
  const rankings = window.localStorage.getItem("rankings");
  const categoryHours = window.localStorage.getItem("categoryHours");
  const satisfaction = window.localStorage.getItem("satisfaction");

  let parsedRankings: string[][] = [];
  let parsedCategoryHours: number[] = [];
  let parsedSatisfaction: number[] = [];

  if (rankings) {
    try {
      const parsed = JSON.parse(rankings);
      if (Array.isArray(parsed) && parsed.every(item => Array.isArray(item) && item.every(subItem => typeof subItem === 'string'))) {
        parsedRankings = parsed;
      }
    } catch (e) {
      console.error("Failed to parse cached rankings:", e);
      throw e;
    }
  } else {
    throw new Error("No rankings found in local storage");
  }

  if (categoryHours) {
    try {
      const parsed = JSON.parse(categoryHours);
      if (Array.isArray(parsed) && parsed.every(item => typeof item === 'number')) {
        parsedCategoryHours = parsed;
      }
    } catch (e) {
      console.error("Failed to parse cached category hours:", e);
      throw e;
    }
  } else {
    throw new Error("No category hours found in local storage");
  }

  if (satisfaction) {
    try {
      const parsed = JSON.parse(satisfaction);
      if (Array.isArray(parsed) && parsed.every(item => typeof item === 'number')) {
        parsedSatisfaction = parsed;
      }
    } catch (e) {
      console.error("Failed to parse cached satisfaction:", e);
      throw e;
    }
  } else {
    throw new Error("No satisfaction found in local storage");
  }

  const combinedData: DataPoint[] = categories.map((category, index) => ({
    category,
    hours: parsedCategoryHours[index] || 0,
    satisfaction: parsedSatisfaction[index] || 0,
    importance: parsedRankings.findIndex(categoryList => categoryList.includes(category)),
  }));

  // console.log('parsedRankings', parsedRankings);
  // console.log('combinedData', combinedData);
  return combinedData;
};

const ChartComponent: React.FC<ChartComponentProps> = ({ data, categories }) => {
  const [chartData, setChartData] = React.useState<DataPoint[]>(() => {
    try {
      return buildChartDataFromLocalStorage(categories);
    } catch (e) {
      console.error(e);
    }

    // otherwise return default data
    return data;
  });

  const navigate = useNavigate();

  return (
    <div style={{ width: '80%' }}>
      <PrevButton handlePrevButton={() => navigate('/satisfaction')} />
      <h2>2x2 Strategic Life Portfolio</h2>
      <p>Notice what areas are neglected in the top left quadrant. And what areas you can spend less time in on the bottom right quadrant. The size of the bubble signifies time spent. </p>
      <VictoryChart
        theme={VictoryTheme.material}
        domain={{ x: [0, 10], y: [0, 10] }}
      >
        <VictoryAxis
          label="Satisfaction"
          style={{
            axisLabel: { padding: 30 },
          }}
        />
        <VictoryAxis
          dependentAxis
          label="Importance"
          style={{
            axisLabel: { padding: 40 },
          }}
        />
        <VictoryScatter
          style={{ data: { fill: "#c43a31", stroke: '#000', strokeWidth: 1 } }}
          x={(datum) => datum.satisfaction}
          y={(dataum) => dataum.importance}
          labels={({ datum }) => datum.category}
          bubbleProperty="hours"
          maxBubbleSize={25}
          minBubbleSize={5}
          data={chartData}
          labelComponent={<VictoryLabel style={{ fontSize: 5, fill: 'white' }} dx={0} dy={0} />}
        />
        <VictoryLine
          style={{
            data: { stroke: "#000", strokeWidth: 1 },
          }}
          x={() => 5}
        />
        <VictoryLine
          style={{
            data: { stroke: "#000", strokeWidth: 1 },
          }}
          y={() => 5}
        />
      </VictoryChart>
    </div>
  )
};

export default ChartComponent;