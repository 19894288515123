import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import NextButton from '../Components/NextButton';
import PrevButton from '../Components/PrevButton';
import CategoryBox from './CategoryBox';
import RankingBox from './RankingBox';

interface RankingComponentProps {
    categories: string[];
}

// TODO: cannot move items back into unranked
// TODO: copy kind of sucks
// TODO: if you have too many items in a rank, they don't show up
const RankingComponent: React.FC<RankingComponentProps> = ({ categories }) => {
    const [rankings, setRankings] = useState<string[][]>(() => {
        const cached = window.localStorage.getItem("rankings");
        if (cached) {
            try {
                const parsed = JSON.parse(cached);
                if (Array.isArray(parsed) && parsed.every(item => Array.isArray(item) && item.every(subItem => typeof subItem === 'string'))) {
                    return parsed;
                }
            } catch (e) {
                console.error("Failed to parse cached rankings hours:", e);
            }
        }
    
        return Array.from({ length: 11 }, () => []);
    });

    useEffect(() => {
        window.localStorage.setItem("rankings", JSON.stringify(rankings));
    }, [rankings]);

    const navigate = useNavigate();

    const moveCategory = (category: string, rank: number) => {
        setRankings(prevRankings => {
            const newRankings = prevRankings.map(r => [...r]);

            // Remove the category from its current rank
            newRankings.forEach((rankCategories, rankIndex) => {
                newRankings[rankIndex] = rankCategories.filter(cat => cat !== category);
            });

            // Add the category to the new rank
            newRankings[rank].push(category);
            return newRankings;
        });
    };

    const availableCategories = categories.filter(category => !rankings.some(rank => rank.includes(category)));

    const handleNextButton = () => {
        if (categories.every(category => rankings.some(rank => rank.includes(category)))) {
            navigate('/satisfaction');
        } else {
            toast('Please rank all categories before proceeding.', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
    }

    const handlePrevButton = () => {
        navigate('/time-distribution')
    }

    return (
        <>
            <PrevButton handlePrevButton={handlePrevButton} />
            <h1>Rank Each SLU by Importance To You</h1>
            <h3>SLUs can be ranked the same.</h3>
            <DndProvider backend={HTML5Backend}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                    <div style={{ width: '30%', border: '2px solid black' }}>
                        <div>Unranked</div>
                        {availableCategories.map((category, index) => (
                            <CategoryBox key={category} category={category} />
                        ))}
                    </div>
                    <div style={{ width: '5%' }}></div>
                    <div style={{ width: '65%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>Most Important</div>
                        {rankings.slice().reverse().map((rankCategories, index) => (
                            <RankingBox key={index} rank={10 - index} categories={rankCategories} moveCategory={moveCategory} />
                        ))}
                        <div>Least Important</div>
                    </div>
                </div>
            </DndProvider>
            <NextButton handleNextButton={handleNextButton} />
            <ToastContainer />
        </>
    );
};

export default RankingComponent;