import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import NextButton from '../Components/NextButton';
import PrevButton from '../Components/PrevButton';
import './SatisfactionComponent.css'; // Import the CSS file

interface SatisfactionComponentProps {
    categories: string[];
}

const SatisfactionComponent: React.FC<SatisfactionComponentProps> = ({ categories }) => {
    const [satisfaction, setSatisfaction] = useState<number[]>(() => {
        const savedSatisfaction = localStorage.getItem('satisfaction');
        return savedSatisfaction ? JSON.parse(savedSatisfaction) : new Array(categories.length).fill(5);
    });

    useEffect(() => {
        localStorage.setItem('satisfaction', JSON.stringify(satisfaction));
    }, [satisfaction]);

    const navigate = useNavigate();

    const handleSliderChange = (index: number, value: number) => {
        const newSatisfaction = [...satisfaction];
        newSatisfaction[index] = value;
        setSatisfaction(newSatisfaction);
    };

    return (
        <>
            <PrevButton handlePrevButton={() => navigate('/ranking')} />
            <div className="satisfaction-container">
                <h2 className="header">Rate How Satisfied You Are At Each SLU</h2>
                <h4>10 being very satisfied, 0 be not satisfied at all</h4>
                {categories.map((category, index) => (
                    <div key={index} className="satisfaction-item">
                        <label className="category-label">{category}</label>
                        <input
                            type="range"
                            min="0"
                            max="10"
                            value={satisfaction[index]}
                            onChange={(e) => handleSliderChange(index, Number(e.target.value))}
                            className="slider"
                        />
                        <span className="satisfaction-value">{satisfaction[index]}</span>
                    </div>
                ))}
            </div>
            <NextButton handleNextButton={() => navigate('/chart')} />
        </>

    );
};

export default SatisfactionComponent;