
function NextPageComponent() {
    return (
        <div>
            <h1>Next Page</h1>
            <p>This is the next page of the form.</p>
        </div>
    );
}

export default NextPageComponent;