import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSlider from 'react-slider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NextButton from '../Components/NextButton';
import PrevButton from '../Components/PrevButton';

interface Props {
    categories: string[];
    totalHours: number;
}

const descriptions = [
    "Time with partner, dates",
    "Engaging with kids, parents, siblings",
    "Time with friends",
    "Exercise, physical therapy",
    "Psychotherapy, meditation",
    "Religious practice",
    "Membership in local clubs, jury duty",
    "Volunteering, activism",
    "Work",
    "Classes, training",
    "Planning, investing",
    "Reading, collectibles",
    "Social media, TV, gaming",
    "Vacations, theater, sporting events",
    "Eating, sleeping",
    "Commuting, housework"
];

const DistributionComponent: React.FC<Props> = ({ categories, totalHours }) => {
    const [categoryHours, setCategoryHours] = useState<number[]>(() => {
        const cached = window.localStorage.getItem("categoryHours");
        if (cached) {
            try {
                const parsed = JSON.parse(cached);
                if (Array.isArray(parsed) && parsed.every(item => typeof item === 'number')) {
                    return parsed;
                }
            } catch (e) {
                console.error("Failed to parse cached category hours:", e);
            }
        }
        return categories.map(() => 0);
    });

    useEffect(() => {
        window.localStorage.setItem("categoryHours", JSON.stringify(categoryHours));
    }, [categoryHours]);

    const navigate = useNavigate();

    const handleBarDrag = (index: number, newHours: number) => {
        setCategoryHours(prevHours => {
            const updatedHours = [...prevHours];
            updatedHours[index] = newHours;
            return updatedHours;
        });
    };

    const handleNextButton = () => {
        if (totalSpentHours != totalHours) {
            toast(
                "Please allocate all hours before proceeding.",
                {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    // transition: Bounce,
                }
            );
        } else {
            navigate('/ranking');
        }
    };

    const totalSpentHours = categoryHours.reduce((acc, curr) => acc + curr, 0);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <PrevButton handlePrevButton={() => navigate('/')} />

            {/* Text */}
            <div>
                <h2>How Did You Spend Your Average Week The Past Year?</h2>
                <h4>{totalSpentHours} / {totalHours} Allocated Hours</h4>
            </div>

            {/* Bars */}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100%', width: '100%' }}>
                {categories.map((category, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '5px' }}>
                        <div style={{ width: '35%', textAlign: 'right', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: '70%' }}>{category}</div>
                            <div style={{ height: '30%', fontSize: '12px' }}>{descriptions[index]}</div>
                        </div>
                        <div style={{ width: '5%' }}></div>
                        <div style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '5px', textAlign: 'right', display: 'flex', alignItems: 'center' }}>0</div>
                            <ReactSlider
                                className="horizontal-slider"
                                markClassName="example-mark"
                                min={0}
                                max={categoryHours[index] + totalHours - totalSpentHours}
                                value={categoryHours[index]} // Have to set the value for loading from localstorage
                                thumbClassName="example-thumb"
                                trackClassName="example-track-1"
                                renderThumb={({ key, ...rest }, state) =>
                                    <div {...rest} key={key} style={{ ...rest.style, color: 'black', backgroundColor: 'lightblue', border: '2px solid black', borderRadius: '5px' }}>
                                        {state.valueNow}
                                    </div>
                                }
                                renderTrack={({ key, ...rest }, state) => <div {...rest} key={key} style={{ ...rest.style, backgroundColor: 'lightblue' }}> </div>}
                                onChange={(value) => handleBarDrag(index, value)}
                            />
                            <div style={{ marginLeft: '5px', textAlign: 'left', display: 'flex', alignItems: 'center' }}>{categoryHours[index] + totalHours - totalSpentHours}</div>
                        </div>
                    </div>
                ))}
            </div>

            <NextButton handleNextButton={handleNextButton} />

            <ToastContainer />
        </div>
    );
};

export default DistributionComponent;