import React from 'react';
import { useDrag } from 'react-dnd';

interface CategoryBoxProps {
  category: string;
}

const CategoryBox: React.FC<CategoryBoxProps> = ({ category }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CATEGORY',
    item: { category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        padding: '8px',
        margin: '4px',
        backgroundColor: 'lightblue',
        color: 'black',
        border: '1px solid black',
        borderRadius: '4px',
        cursor: 'move',
        fontSize: '15px',
        width: 'fit-content'
      }}
    >
      {category}
    </div>
  );
};

export default CategoryBox;